.metalBar.true {
    min-width: 30%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    border: none;
    border-radius: 0.3em;
    height: 2.75em;
    line-height: 2.5em;
    text-transform: uppercase;
    padding: 0 1em;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
    inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
    inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    background-image: linear-gradient(
      160deg,
      #a54e07,
      #b47e11,
      #fef1a2,
      #bc881b,
      #a54e07
    );
    border: 1px solid #a55d07;
    color: rgb(0, 0, 0);
    text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-size: 100% 100%;
    background-position: center;
}

.metalBar.false {
    min-width: 30%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    border: none;
    border-radius: 0.3em;
    height: 2.75em;
    line-height: 2.5em;
    text-transform: uppercase;
    padding: 0 1em;
    background-size: 150% 150%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(150, 150, 150, 0.4), /* Changed to grey tones */
    inset 0 -2px 5px 1px rgba(128, 128, 128, 1), /* Darker silver for depth */
    inset 0 -1px 1px 3px rgba(200, 200, 200, 1); /* Lighter silver for highlights */
    background-image: linear-gradient(
      160deg,
      #7a7a7a, /* Darker silver at the edges */
      #a0a0a0, /* Medium silver transitioning in */
      #e0e0e0, /* Lighter silver, approaching the center */
      rgb(248, 248, 248), /* Brightest point for shine in the middle */
      #e0e0e0, /* Light silver after the middle */
      #a0a0a0, /* Medium silver transitioning out */
      #7a7a7a  /* Darker silver at the edges */
    );
    border: 1px solid #a0a0a0;
    color: rgb(0, 0, 0);
    text-shadow: 0 2px 2px rgba(180, 180, 180, 1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-size: 100% 100%;
    background-position: center;
}


@media screen and (max-width: 550px) {
  .metalBar {
    width: 100%;
  }
}