.copy-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  text-align: center;
  margin: 10px;
}

.copy-text {
  width: 300px;
  height: 82px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  resize: none;
}

.copy-button {
  height: 82px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-top: 0px !important;
}

.copy-button:hover {
  background-color: #0056b3;
}

.copy-success {
  color: green;
  margin-top: 10px;
}
