.attendance__container {
    z-index: 999;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 28px;
    width: 100%;
    height: 100%;
    background-color: rgba(184, 184, 184, 0.7);
}



.attendance__container .attendance__form .detailsPath__container {
    width: 100%;
}

.attendance__container .attendance__form .detailsPath__container .certificateName {
    margin-left: 28px;
    align-items: center;
}


.attendance__container .attendance__form .detailsPath__container .empty__projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid black;
    height: 150px;
    margin-bottom: 120px;
}


.attendance__container .attendance__form .detailsPath__container ul li {
    margin-bottom: 12px;
    font-family: 18px;
}


.attendance__container .attendance__form .detailsPath__container .empty__projects p {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}




.attendance__container .attendance__form {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 94vh;
    height: 100vh;
    padding: 38px;
}

.attendance__container .attendance__form .attendance__icon__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 22px;
}

.attendance__container .attendance__form .pathSelect {
    height: 34px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 32px;
}

.attendance__container .attendance__form .textAreaNotes {
    width: 100%;
    height: 50%;
    margin-bottom: 18px;
    padding: 9px;
    font-size: 16px;
}



.attendance__container .attendance__form .options__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.attendance__container .attendance__form .options__container select {
    height: 32px;
    font-weight: 18px;
    margin-bottom: 18px;
    font-size: 18px;
}

.attendance__container .attendance__form .project-selector {
    height: 32px;
    font-weight: 18px;
    margin-bottom: 18px;
    font-size: 18px;
    width: 100%;
}

.attendance__container .attendance__form .submitNotes {
    width: 100%;
    height: 34px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.attendance__container .attendance__form .submitNotes:hover {
    background-color: rgb(192, 0, 0);
}


.attendance__container .attendance__form .awardCertificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 22px;
}

.attendance__container .attendance__form .awardCertificate-container .certSelector {
    width: 100%;
    height: 34px;
    margin-bottom: 18px;
    font-size: 18px;
    padding: 7px;
}

.attendance__container .attendance__form .awardCertificate-container .certButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 34px;
    color: white;
    background-color: rgb(214, 192, 26);
    align-self: center;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.attendance__container .attendance__form .awardCertificate-container .certButton:hover {
    background-color: rgb(168, 151, 19);
}