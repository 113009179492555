.schedule__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 10px;
}

.schedule__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.schedule__container .reschedule__display__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
    margin-left: 32px;
    margin-top: 40px;
    min-height: 150px;
    height: 100%;
    border-radius: 12px;
    padding: 22px;
    -webkit-box-shadow: 0px 20px 0px -10px #FFFFFF, 0px -20px 0px -10px #FFFFFF, 20px 0px 0px -10px #FFFFFF, -20px 0px 0px -10px #FFFFFF, 0px 0px 0px 10px gold, 5px 5px 35px 12px rgba(0,0,0,0.29); 
    box-shadow: 0px 20px 0px -10px #FFFFFF, 0px -20px 0px -10px #FFFFFF, 20px 0px 0px -10px #FFFFFF, -20px 0px 0px -10px #FFFFFF, 0px 0px 0px 10px gold, 5px 5px 35px 12px rgba(0,0,0,0.29);
}

.schedule__container .reschedule__display__container h2 {
    margin-bottom: 18px;
}

.schedule__container .reschedule__display__container .rescheduled__cards {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: visible;  
    padding: 20px;
    margin: -20px;
    width: 100%;
}

.schedule__container .reschedule__display__container .rescheduled__cards::-webkit-scrollbar {
    display: none;
}

.schedule__container .reschedule__display__container .rescheduled__cards .rescheduled__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 16px;
    margin-right: 52px;
    -webkit-box-shadow: 0px 20px 0px -10px #FFFFFF, 0px -20px 0px -10px #FFFFFF, 20px 0px 0px -10px #FFFFFF, -20px 0px 0px -10px #FFFFFF, 0px 0px 0px 10px rgb(157, 7, 207), 5px 5px 35px 12px rgba(0,0,0,0.29); 
    box-shadow: 0px 20px 0px -10px #FFFFFF, 0px -20px 0px -10px #FFFFFF, 20px 0px 0px -10px #FFFFFF, -20px 0px 0px -10px #FFFFFF, 0px 0px 0px 10px rgb(157, 7, 207), 5px 5px 35px 12px rgba(0,0,0,0.29);   
}

.schedule__container .reschedule__display__container .rescheduled__cards .rescheduled__card h3 {
    font-size: 16px;
    margin-bottom: 8px;
    text-decoration: underline;
}

.schedule__container .reschedule__display__container .rescheduled__cards .rescheduled__card .startClassButton {
    width: 100%;
    background-color: red;
    border: none;
    color: white;
    text-decoration: none;
    font-size: 16px;
    height: 32px;
}

.schedule__container .reschedule__display__container .rescheduled__cards .rescheduled__card .startClassButton a {
    color: white;
    text-decoration: none;;
}






.schedule__container .schedule__button__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 28px;
}

.schedule__container .schedule__button__container .update {
    width: 15%;
    height: 34px;
    border: none;
    background-color: rgba(37, 117, 252, 0.9);
    color: #fff;
    border-radius: 9px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.schedule__container .schedule__button__container .update:hover {
    background-color: rgba(27, 86, 187, 0.9);
}





.schedule__container .classes__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.schedule__container .classes__container .day__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
}

.schedule__container .classes__container .day__container h1 {
    margin-bottom: 36px;
}

.schedule__container .classes__container .day__container .class__card__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}


.schedule__container .classes__container .day__container .class__card__container .no-classes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 80%;
    height: 80px;
    border-radius: 0px;
    border: 2px solid rgb(0,0,80);
    margin-bottom: 44px;

    -webkit-box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15); 
    box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15);
}


.late__container {
    z-index: 999;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 28px;
    width: 100%;
    height: 100%;
    background-color: rgba(184, 184, 184, 0.7);
}

.late__container .late__form {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 94vh;
    padding: 38px;
}

.late__container .late__form .late__icon__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 22px;
}

.late__container .late__form h1 {
    text-align: center;
    margin-bottom: 18px;
}

.late__container .late__form p {
    font-size: 24px;
    text-align: center;
    color: red;
    margin-top: 118px;
    margin-bottom: 118px;
    font-weight: 600;
}

.late__container .late__form .textButton {
    background-color: red;
    cursor: pointer;
    transition: 0.3s all ease-in;
    width: 100%;
    height: 34px;
}

.late__container .late__form .textButton:hover {
    background-color: rgb(192, 0, 0);
}








.group-attendance__container {
    z-index: 999;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 28px;
    width: 100%;
    height: 100%;
    background-color: rgba(184, 184, 184, 0.7);
}

.group-attendance__container .group-attendance__form {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 94vh;
    height: 100vh;
    padding: 38px;
}

.group-attendance__container .group-attendance__form .group-attendance__icon__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 22px;
}

.group-attendance__container .group-attendance__form .groupName {
    margin-bottom: 22px;
}

.group-attendance__container .group-attendance__form .groupAttendance__textarea {
    width: 100%;
    height: 50%;
    padding: 14px;
    font-size: 18px;
    margin-bottom: 22px;
}

.group-attendance__container .group-attendance__form .groupAttendance__button {
    width: 100%;
    height: 34px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.group-attendance__container .group-attendance__form .groupAttendance__button:hover {
    background-color: rgb(192, 0, 0);
}


@media screen and (max-width: 500px) {
    .schedule__container {
        padding: 0px;
    }
}