/* Sidebar container */
.sidebar {
    position: fixed; /* Keep the sidebar separate from main content */
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 60);
    width: 220px; /* Expanded width */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    transition: width 0.4s ease-in-out;
    z-index: 9999; /* Ensure it stays on top */
}

/* Sidebar closed state */
.sidebar.closed {
    width: 80px; /* Retracted width */
    align-items: center;
}

/* Toggle button */
.toggle-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: -20px;
    background-color: rgba(255, 0, 0, 0.493); /* Semi-transparent background */
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: transform 0.4s ease-in-out, background-color 0.3s ease;
    z-index: 100; /* Ensure it stays above other elements */
}

/* Rotate arrow when sidebar is closed */
.toggle-btn.rotate .arrow-icon {
    transform: rotate(180deg);
}

/* Logo container */
.logo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease-in-out;

    margin-top: 18px;
    margin-bottom: 44px;
}

.sidebar.closed .logo-container {
    transform: translateY(20px);
}

.logo-container img {
    width: 60%;
    max-width: 100px;
    object-fit: contain;
}

/* Header and course options */
.header-container h2 {
    color: white;
    font-size: 18px;
    align-self: flex-start;
    margin-left: 8px;
    margin-bottom: 14px;
}

.header-container.show {
    opacity: 1;
    transition: opacity 0.3s;
}

.header-container.hide {
    opacity: 0;
    transition: opacity 0.3s;
}

.course-option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 12px;
    color: white;
    transition: color 0.3s, padding-left 0.4s ease-in-out;
    margin-left: 16px;
    margin-bottom: 8px;
}

.course-option.centered {
    justify-content: center;
    width: 100%;
    margin-left: 0;
}

.sidebar.closed .course-option {
    padding-left: 24px;
}

.course-option:hover {
    color: rgb(255, 210, 97);
}

/* Icons and text */
.icon {
    font-size: 20px;
    transition: font-size 0.3s;
}

.sidebar.closed .icon {
    font-size: 28px;
}

.course-option p {
    font-size: 14px;
    transition: opacity 0.3s ease-in-out;
}

.sidebar.closed .course-option p {
    opacity: 0;
}


/* Logout option at bottom */
.logout-option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 10px 12px;
    color: white;
    transition: color 0.3s, padding-left 0.4s ease-in-out;
    margin-top: auto; /* Positions it at the bottom */
    margin-left: 16px;
}

.logout-option.centered {
    justify-content: center;
    width: 100%;
    margin-left: 0;
}

.sidebar.closed .logout-option {
    padding-left: 24px;
}

.logout-option:hover {
    color: rgb(255, 210, 97);
}

@media screen and (max-width: 500px) {
    .sidebar {
        display: none;
    }
}