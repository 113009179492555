.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.281);
    display: flex;
    align-items: flex-start;
    justify-content: cente;
    z-index: 10; 
}

.overlay .details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.overlay .details-container .details-form {
    position: relative;
    width: 50%;
    height: 80vh;
    background-color: white;
    border: 2px solid blue;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 80px;
}

.overlay .details-container .details-form .details-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 62px;
    padding-right: 24px;
    width: 100%;
}

.overlay .details-container .details-form .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
}

.overlay .details-container .details-form .label-container h3 {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.overlay .details-container .details-form .project-tag-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.overlay .details-container .details-form .project-tag-container .project-tag-true {
    background-color: rgb(76, 255, 115);
    color: black;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 4px;
}

.overlay .details-container .details-form .project-tag-container .project-tag-true h3 {
    font-size: 18px;
}

.overlay .details-container .details-form .project-tag-container .project-tag-false {
    background-color: red;
    color: black;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 4px;
}

.overlay .details-container .details-form .project-tag-container .project-tag-false h3 {
    font-size: 18px;
}

.overlay .details-container .details-form .certificates-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
}

.overlay .details-container .details-form .certificates-container .certificates {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    overflow-y: scroll;
}

.overlay .details-container .details-form .certificates-container .certificates .certificate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(76, 255, 115);
    width: 80%;
    height: 42px;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 12px;
}

.overlay .details-container .details-form .award-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 18px;
}

.overlay .details-container .details-form .award-container p {
    font-weight: 600;
    margin-bottom: 12px;
}

.overlay .details-container .details-form .award-container select {
    height: 32px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 18px;
}

.overlay .details-container .details-form .award-container .certButton {
    background-color: gold;
    border-radius: 4px;
    height: 42px;
    border: none;
    color: white;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.overlay .details-container .details-form .award-container .certButton:hover {
    background-color: rgb(199, 169, 0);
}

@media screen and (max-width: 1000px) {
    .overlay .details-container .details-form {
        position: relative;
        width: 80%;
        height: 70vh;
        background-color: white;
        border: 2px solid blue;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 80px;
    }
}

@media screen and (max-width: 600px) {
    .overlay .details-container .details-form {
        position: relative;
        width: 100%;
        height: 80vh;
        background-color: white;
        border: 2px solid blue;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 80px;
    }
}