.project-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 31%;
    height: 500px;
    margin-bottom: 34px;

    -webkit-box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.1);
}

.project-card .coverimage-container {
    position: relative;
    width: 100%;
    height: 75%;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.project-card .coverimage-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-card .project-card-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px;
    width: 100%;
    /* Remove the fixed height */
    flex-grow: 1;
}

.project-card .project-card-bottom h2 {
    margin-bottom: 12px;
    font-weight: 400;
    word-break: break-word; /* Optional: to handle long text without overflow */
}



.project-card .project-card-bottom .animButton.scratch {
    width: 100%;
    height: 42px; /* Fixed height */
    --black-700: #FFA500;
    --border_radius: 8px;
    --transtion: 0.3s ease-in-out;
    --offset: 2px;

    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    background-color: transparent;
    border: none;
    border-radius: var(--border_radius);
    transition: transform var(--transtion);
    transform-origin: center;

    /* Adjust padding to maintain 42px height */
    padding: 0 1rem;
}

.project-card .project-card-bottom .animButton.scratch::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--black-700); /* Orange background */
    
    border-radius: var(--border_radius);
    box-shadow: 
      inset 0 1px 2px hsl(0, 0%, 100%), 
      inset 0 -1px 2px 0 #FFA500,
      0px 6px 12px -4px rgba(255, 165, 0, 0.8),
      0 0 5px 4px rgba(255, 215, 0, 0.5); /* Enhanced outer glow */

    transition: all var(--transtion);
    z-index: 0;
}

.project-card .project-card-bottom .animButton.scratch::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        at 51% 89%, rgba(255, 215, 0, 0.7) 0px, transparent 50%
      ),
      radial-gradient(at 100% 100%, rgba(255, 165, 0, 0.6) 0px, transparent 50%),
      radial-gradient(at 22% 91%, rgba(255, 255, 0, 0.5) 0px, transparent 50%);
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.project-card .project-card-bottom .animButton.scratch:is(:hover, :focus-visible) {
    --active: 1;
    background-color: #FFA500;
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.7), 0 0 15px rgba(255, 215, 0, 0.6);
}

.project-card .project-card-bottom .animButton.scratch .dots_border {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: var(--border_radius);
    z-index: -10;
}

.project-card .project-card-bottom .animButton.scratch .dots_border::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);

    width: 100%;
    height: 2rem;
    background: linear-gradient(
        90deg,
        rgba(255, 165, 0, 0.8) 0%, 
        rgba(255, 215, 0, 0.8) 50%, 
        rgba(255, 255, 0, 0.8) 100%
    );
    box-shadow: 
      0 0 12px rgba(255, 165, 0, 0.7), 
      0 0 20px rgba(255, 215, 0, 0.6), 
      0 0 30px rgba(255, 255, 0, 0.5);
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.project-card .project-card-bottom .animButton.scratch .sparkle {
    position: relative;
    z-index: 10;
    width: 1.75rem;
}

.project-card .project-card-bottom .animButton.scratch .sparkle .path {
    fill: currentColor;
    stroke: currentColor;
    transform-origin: center;
    color: hsl(0, 0%, 100%);
}

.project-card .project-card-bottom .animButton.scratch:is(:hover, :focus) .sparkle .path {
    animation: path 1.5s linear 0.5s infinite;
}

@keyframes path {
    0%, 34%, 71%, 100% {
        transform: scale(1);
    }
    17% {
        transform: scale(1.3);
    }
    49% {
        transform: scale(1.3);
    }
    83% {
        transform: scale(1.3);
    }
}

.project-card .project-card-bottom .animButton.scratch .text_button {
    position: relative;
    z-index: 10;
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 1) 100%,
        hsla(0, 0%, 100%, var(--active, 0)) 120%
    );
    background-clip: text;
    font-size: 1rem;
    font-weight: 700;
    color: transparent;
}


.project-card .project-card-bottom .animButton.python {
    width: 100%;
    height: 42px; /* Fixed height */
    --blue-700: #121FCF; /* Deep blue color */
    --border_radius: 8px;
    --transtion: 0.3s ease-in-out;
    --offset: 2px;

    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    background-color: transparent;
    border: none;
    border-radius: var(--border_radius);
    transition: transform var(--transtion);
    transform-origin: center;

    /* Adjust padding to maintain 42px height */
    padding: 0 1rem;
}

.project-card .project-card-bottom .animButton.python::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--blue-700); /* Blue background */
    
    border-radius: var(--border_radius);
    box-shadow: 
      inset 0 1px 2px hsl(0, 0%, 100%), 
      inset 0 -1px 2px 0 #121FCF,
      0px 6px 12px -4px rgba(18, 31, 207, 0.8),
      0 0 5px 4px rgba(60, 80, 255, 0.5); /* Enhanced outer glow */

    transition: all var(--transtion);
    z-index: 0;
}

.project-card .project-card-bottom .animButton.python::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        at 51% 89%, rgba(60, 80, 255, 0.7) 0px, transparent 50%
      ),
      radial-gradient(at 100% 100%, rgba(30, 45, 255, 0.6) 0px, transparent 50%),
      radial-gradient(at 22% 91%, rgba(120, 140, 255, 0.5) 0px, transparent 50%);
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.project-card .project-card-bottom .animButton.python:is(:hover, :focus-visible) {
    --active: 1;
    background-color: #121FCF;
    box-shadow: 0 0 10px rgba(60, 80, 255, 0.7), 0 0 15px rgba(30, 45, 255, 0.6);
}

.project-card .project-card-bottom .animButton.python .dots_border {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: var(--border_radius);
    z-index: -10;
}

.project-card .project-card-bottom .animButton.python .dots_border::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);

    width: 100%;
    height: 2rem;
    background: linear-gradient(
        90deg,
        rgba(30, 45, 255, 0.8) 0%, 
        rgba(60, 80, 255, 0.8) 50%, 
        rgba(120, 140, 255, 0.8) 100%
    );
    box-shadow: 
      0 0 12px rgba(30, 45, 255, 0.7), 
      0 0 20px rgba(60, 80, 255, 0.6), 
      0 0 30px rgba(120, 140, 255, 0.5);
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.project-card .project-card-bottom .animButton.python .sparkle {
    position: relative;
    z-index: 10;
    width: 1.75rem;
}

.project-card .project-card-bottom .animButton.python .sparkle .path {
    fill: currentColor;
    stroke: currentColor;
    transform-origin: center;
    color: hsl(0, 0%, 100%);
}

.project-card .project-card-bottom .animButton.python:is(:hover, :focus) .sparkle .path {
    animation: path 1.5s linear 0.5s infinite;
}

@keyframes path {
    0%, 34%, 71%, 100% {
        transform: scale(1);
    }
    17% {
        transform: scale(1.3);
    }
    49% {
        transform: scale(1.3);
    }
    83% {
        transform: scale(1.3);
    }
}

.project-card .project-card-bottom .animButton.python .text_button {
    position: relative;
    z-index: 10;
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 1) 100%,
        hsla(0, 0%, 100%, var(--active, 0)) 120%
    );
    background-clip: text;
    font-size: 1rem;
    font-weight: 700;
    color: transparent;
}


.project-card .project-card-bottom .animButton.roblox {
    width: 100%;
    height: 42px; /* Fixed height */
    --purple-700: #940DFF; /* Rich purple color */
    --border_radius: 8px;
    --transtion: 0.3s ease-in-out;
    --offset: 2px;

    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    background-color: transparent;
    border: none;
    border-radius: var(--border_radius);
    transition: transform var(--transtion);
    transform-origin: center;

    /* Adjust padding to maintain 42px height */
    padding: 0 1rem;
}

.project-card .project-card-bottom .animButton.roblox::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--purple-700); /* Purple background */
    
    border-radius: var(--border_radius);
    box-shadow: 
      inset 0 1px 2px hsl(0, 0%, 100%), 
      inset 0 -1px 2px 0 #940DFF,
      0px 6px 12px -4px rgba(148, 13, 255, 0.8),
      0 0 5px 4px rgba(180, 80, 255, 0.5); /* Enhanced outer glow */

    transition: all var(--transtion);
    z-index: 0;
}

.project-card .project-card-bottom .animButton.roblox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        at 51% 89%, rgba(180, 80, 255, 0.7) 0px, transparent 50%
      ),
      radial-gradient(at 100% 100%, rgba(148, 13, 255, 0.6) 0px, transparent 50%),
      radial-gradient(at 22% 91%, rgba(200, 120, 255, 0.5) 0px, transparent 50%);
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.project-card .project-card-bottom .animButton.roblox:is(:hover, :focus-visible) {
    --active: 1;
    background-color: #940DFF;
    box-shadow: 0 0 10px rgba(180, 80, 255, 0.7), 0 0 15px rgba(148, 13, 255, 0.6);
}

.project-card .project-card-bottom .animButton.roblox .dots_border {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: var(--border_radius);
    z-index: -10;
}

.project-card .project-card-bottom .animButton.roblox .dots_border::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);

    width: 100%;
    height: 2rem;
    background: linear-gradient(
        90deg,
        rgba(148, 13, 255, 0.8) 0%, 
        rgba(180, 80, 255, 0.8) 50%, 
        rgba(200, 120, 255, 0.8) 100%
    );
    box-shadow: 
      0 0 12px rgba(148, 13, 255, 0.7), 
      0 0 20px rgba(180, 80, 255, 0.6), 
      0 0 30px rgba(200, 120, 255, 0.5);
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.project-card .project-card-bottom .animButton.roblox .sparkle {
    position: relative;
    z-index: 10;
    width: 1.75rem;
}

.project-card .project-card-bottom .animButton.roblox .sparkle .path {
    fill: currentColor;
    stroke: currentColor;
    transform-origin: center;
    color: hsl(0, 0%, 100%);
}

.project-card .project-card-bottom .animButton.roblox:is(:hover, :focus) .sparkle .path {
    animation: path 1.5s linear 0.5s infinite;
}

@keyframes path {
    0%, 34%, 71%, 100% {
        transform: scale(1);
    }
    17% {
        transform: scale(1.3);
    }
    49% {
        transform: scale(1.3);
    }
    83% {
        transform: scale(1.3);
    }
}

.project-card .project-card-bottom .animButton.roblox .text_button {
    position: relative;
    z-index: 10;
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 1) 100%,
        hsla(0, 0%, 100%, var(--active, 0)) 120%
    );
    background-clip: text;
    font-size: 1rem;
    font-weight: 700;
    color: transparent;
}


.project-card .project-card-bottom .animButton.fullstack {
    width: 100%;
    height: 42px; /* Fixed height */
    --red-700: #FF0000; /* Bright red color */
    --border_radius: 8px;
    --transtion: 0.3s ease-in-out;
    --offset: 2px;

    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    background-color: transparent;
    border: none;
    border-radius: var(--border_radius);
    transition: transform var(--transtion);
    transform-origin: center;

    /* Adjust padding to maintain 42px height */
    padding: 0 1rem;
}

.project-card .project-card-bottom .animButton.fullstack::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--red-700); /* Red background */
    
    border-radius: var(--border_radius);
    box-shadow: 
      inset 0 1px 2px hsl(0, 0%, 100%), 
      inset 0 -1px 2px 0 #FF0000,
      0px 6px 12px -4px rgba(255, 0, 0, 0.8),
      0 0 5px 4px rgba(255, 80, 80, 0.5); /* Enhanced outer glow */

    transition: all var(--transtion);
    z-index: 0;
}

.project-card .project-card-bottom .animButton.fullstack::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        at 51% 89%, rgba(255, 80, 80, 0.7) 0px, transparent 50%
      ),
      radial-gradient(at 100% 100%, rgba(255, 0, 0, 0.6) 0px, transparent 50%),
      radial-gradient(at 22% 91%, rgba(255, 120, 120, 0.5) 0px, transparent 50%);
    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.project-card .project-card-bottom .animButton.fullstack:is(:hover, :focus-visible) {
    --active: 1;
    background-color: #FF0000;
    box-shadow: 0 0 10px rgba(255, 80, 80, 0.7), 0 0 15px rgba(255, 0, 0, 0.6);
}

.project-card .project-card-bottom .animButton.fullstack .dots_border {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: var(--border_radius);
    z-index: -10;
}

.project-card .project-card-bottom .animButton.fullstack .dots_border::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);

    width: 100%;
    height: 2rem;
    background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.8) 0%, 
        rgba(255, 80, 80, 0.8) 50%, 
        rgba(255, 120, 120, 0.8) 100%
    );
    box-shadow: 
      0 0 12px rgba(255, 0, 0, 0.7), 
      0 0 20px rgba(255, 80, 80, 0.6), 
      0 0 30px rgba(255, 120, 120, 0.5);
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.project-card .project-card-bottom .animButton.fullstack .sparkle {
    position: relative;
    z-index: 10;
    width: 1.75rem;
}

.project-card .project-card-bottom .animButton.fullstack .sparkle .path {
    fill: currentColor;
    stroke: currentColor;
    transform-origin: center;
    color: hsl(0, 0%, 100%);
}

.project-card .project-card-bottom .animButton.fullstack:is(:hover, :focus) .sparkle .path {
    animation: path 1.5s linear 0.5s infinite;
}

@keyframes path {
    0%, 34%, 71%, 100% {
        transform: scale(1);
    }
    17% {
        transform: scale(1.3);
    }
    49% {
        transform: scale(1.3);
    }
    83% {
        transform: scale(1.3);
    }
}

.project-card .project-card-bottom .animButton.fullstack .text_button {
    position: relative;
    z-index: 10;
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 1) 100%,
        hsla(0, 0%, 100%, var(--active, 0)) 120%
    );
    background-clip: text;
    font-size: 1rem;
    font-weight: 700;
    color: transparent;
}

@media screen and (max-width: 1200px) {
    .project-card {
        width: 48%;
    }
}

@media screen and (max-width: 1000px) {
    .project-card .project-card-bottom h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 800px) {    
    .project-card {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {    
    .project-card .project-card-bottom h2 {
        font-size: 16px;
    }
    
    .project-card {
        width: 100%;
    }
}

