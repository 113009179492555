.join-meeting-page {
    display: flex;
    flex-direction: row;

    width: 100%;
    background-color: transparent;
}

.join-meeting-page .page-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.join-meeting-page .page-right .join__class__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  width: 100%;
  height: 30vh;
}

.join-meeting-page .page-right .meeting__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 40px;
    width: 100%;
}

.join-meeting-page .page-right .meeting__container .meeting__card {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    margin-top: 20px;
}


.join-meeting-page .page-right .meeting__container .cover {
    position: relative;
    height: 70px;
    padding: 0 30px;
    border: 4px solid #04b804; /* Increased border thickness */
    background: white;
    user-select: none;
    white-space: nowrap;
    transition: all .05s linear;
    font-family: inherit;
    margin-bottom: 22px;
}

.join-meeting-page .page-right .meeting__container .cover:before, .cover:after {
    content: "";
    position: absolute;
    background: white;
    transition: all .2s linear;
}

.join-meeting-page .page-right .meeting__container .cover:before {
    width: calc(100% + 8px); /* Adjusted for thicker lines */
    height: calc(100% - 20px); /* Adjusted for thicker lines */
    top: 10px; /* Adjusted for thicker lines */
    left: -4px;
}

.join-meeting-page .page-right .meeting__container .cover:after {
    width: calc(100% - 20px); /* Adjusted for thicker lines */
    height: calc(100% + 8px); /* Adjusted for thicker lines */
    top: -4px;
    left: 10px; /* Adjusted for thicker lines */
}

.join-meeting-page .page-right .meeting__container .cover:hover {
    cursor: crosshair;
}

.join-meeting-page .page-right .meeting__container .cover:active {
    transform: scale(0.95);
}

.join-meeting-page .page-right .meeting__container .cover:hover:before {
    height: calc(100% - 36px); /* Adjusted for thicker lines */
    top: 18px; /* Adjusted for thicker lines */
}

.join-meeting-page .page-right .meeting__container .cover:hover:after {
    width: calc(100% - 36px); /* Adjusted for thicker lines */
    left: 18px; /* Adjusted for thicker lines */
}

.join-meeting-page .page-right .meeting__container .cover .inner {
    font-size: 24px; /* Increased font size */
    font-family: 'Digital-7 Mono', monospace; /* Digital clock style */
    z-index: 3;
    position: relative;
    font-weight: 600;
    color: #04b804;
    letter-spacing: 2px; /* Adds a bit of spacing for a digital look */
}




/* From Uiverse.io by vinodjangid07 */ 
.join-meeting-page .page-right .meeting__container .meeting__card .meeting-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    border-radius: 8px;
    width: 68%;
    height: 150px;
    padding: 28px;
}

.join-meeting-page .page-right .meeting__container .meeting__card .meeting-info h3 {
    color: white;
}


.meeting-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 82px;
  padding-right: 82px;
  background-color: #006aff;
  border: 8px solid #c0dfff;
  color: white;

  border-radius: 50px;
  width: 30%;
  height: 150px;
  cursor: pointer;
  transition: all 0.3s;
}

.meeting-button .text {
  font-size: 1.7em;
  font-weight: 700;
  letter-spacing: 1px;
}

.meeting-button .svg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  height: 100%;
}

.meeting-button .svg svg {
  width: 80px;
  height: 30px;
}

.meeting-button:hover {
  border: 8px solid #b1d8ff;
  background-color: #1b7aff;
}

.meeting-button:active {
  border: 5px solid #c0dfff;
}

.meeting-button:hover .svg svg {
  animation: jello-vertical 0.9s both;
  transform-origin: left;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}




.bricks {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
}

.tooltip-password-container {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 34px;
  cursor: pointer; /* Indicates clickability */
}

.box {
  background-color: #ffcc00;
  color: #432e23;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  border: 2px solid #cc9900;
  border-radius: 4px;
  width: 34px;
  height: 34px;
  z-index: 3;
  transition: background-color 0.3s;
}

.password-text {
  font-size: 16px;
  color: #ff9999;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.8) translate(-50%, -50%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  white-space: nowrap;
}

.tooltip-password-container.show-password .password-text {
  opacity: 1;
  transform: scale(1.1) translate(-50%, -150%);
}

.tooltip-password-container.show-password .box {
  background-color: #ffeb99; /* Optional: Change color when clicked */
}


.box:hover + .mush {
  animation: mush 0.5s linear forwards;
  opacity: 1;
}

@keyframes mush {
  0% {
    transform: scale(0.8) translate(-8px, -0px);
  }
  50% {
    transform: scale(1.1) translate(-8px, -80px);
  }
  100% {
    transform: scale(1.1) translate(-8px, -45px);
  }
}

@media screen and (max-width: 1200px) {
  .join-meeting-page .page-right .meeting__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 20px;
    padding-top: 40px;
    width: 100%;
  }

  .meeting-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 42px;
    padding-right: 42px;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    color: white;
  
    border-radius: 50px;
    width: 30%;
    height: 150px;
    cursor: pointer;
    transition: all 0.3s;
  }
}


@media screen and (max-width: 1200px) {
  .join-meeting-page .page-right .meeting__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 20px;
    padding-top: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .join-meeting-page .page-right .meeting__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 20px;
    padding-top: 40px;
    width: 100%;
  }

  .join-meeting-page .page-right .meeting__container .meeting__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-left: 0px;
    margin-top: 20px;
  }

  .join-meeting-page .page-right .meeting__container .meeting__card .meeting-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    border-radius: 8px;
    width: 100%;
    height: 150px;
    padding: 28px;
    margin-bottom: 24px;
  }

  .meeting-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 82px;
    padding-right: 82px;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    color: white;
  
    border-radius: 50px;
    width: 100%;
    height: 150px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .meeting-button .svg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    margin-left: 22px;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .join-meeting-page .page-right .meeting__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    width: 100%;
  }

  .join-meeting-page .page-right .meeting__container .meeting__card .meeting-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    border-radius: 8px;
    width: 100%;
    height: 150px;
    padding: 8px;
    margin-bottom: 24px;
  }

  .join-meeting-page .page-right .meeting__container .cover {
    height: 80px;
    padding: 0 00px;
    width: 100%;
    border: 4px solid #04b804; /* Increased border thickness */
    background: white;
    user-select: none;
    white-space: nowrap;
    transition: all .05s linear;
    font-family: inherit;
    margin-bottom: 22px;
  }

  .join-meeting-page .page-right .meeting__container .cover .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px; /* Increased font size */
    font-family: 'Digital-7 Mono', monospace; /* Digital clock style */
    z-index: 3;
    position: relative;
    font-weight: 600;
    color: #04b804;
    letter-spacing: 2px; /* Adds a bit of spacing for a digital look */
  }
  
  .meeting-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 32px;
    padding-right: 32px;
    background-color: #006aff;
    border: 8px solid #c0dfff;
    color: white;
  
    border-radius: 50px;
    width: 100%;
    height: 150px;
    cursor: pointer;
    transition: all 0.3s;
  }
}