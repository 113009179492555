.pythonGames__container {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    padding-top: 38px;
    padding-bottom: 38px;
    padding-left: 92px;
    padding-right: 92px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.pythonGames__container .step__selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    background-color: rgb(0, 0, 85);
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 50px;
    margin-top: 18px;
}

.pythonGames__container .step__selector .arrow__icon {
    color: white;
    cursor: pointer;
}

.pythonGames__container .fs__step__container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    margin-top: 42px;
    -webkit-box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15); 
    box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15);
}

.pythonGames__container .fs__step__container .demo__image {
    width: 80%;
    border-radius: 15px;
    margin-top: 44px;
    margin-bottom: 16px;
    -webkit-box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15); 
    box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15);
    align-self: center;
}


.pythonGames__container .fs__step__container h2 {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 22px;
}

.pythonGames__container .fs__step__container h3 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.pythonGames__container .fs__step__container p {
    font-size: 22px;
    margin-top: 16px;
}


.pythonGames__container .fs__step__container ul {
    margin-left: 32px;
    margin-bottom: 22px;
}

.pythonGames__container .fs__step__container ul li {
    font-size: 22px;
    margin-bottom: 12px;
}

.pythonGames__container .fs__step__container ol {
    margin-left: 32px;
    margin-bottom: 32px;
}

.pythonGames__container .fs__step__container ol li {
    font-size: 22px;
    margin-bottom: 12px;
}


.pythonGames__container .step__number {
    display: flex;
    flex-direction: row;
    width: 20%;
    background-color: black;
    height: 34px;
}

.pythonGames__container .step__number .oper__cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 20%;
    cursor: pointer;
}

.pythonGames__container .step__number .oper__cont .operator {
    color: #fff;
}

.pythonGames__container .step__number input {
    font-size: 18px;
    padding-left: 7px;
    width: 60%;
}




.pythonGames__container .downloadImageButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.pythonGames__container h1 {
    text-align: center;
    align-self: center;
    font-size: 32px;
    margin-bottom: 12px;
}

.pythonGames__container .desc {
    text-align: center;
    align-self: center;
    font-size: 28px;
}

.pythonGames__container .intro {
    text-align: center;
    margin-top: 28px;
    margin-bottom: 28px;
}

.pythonGames__container p {
    font-size: 24px;
}

.pythonGames__container h3 {
    margin-top: 22px;
    margin-bottom: 28px;
}

.pythonGames__container h2 {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 22px;
}

.pythonGames__container ul {
    margin-left: 32px;
}

.pythonGames__container ul li {
    font-size: 22px;
    margin-bottom: 12px;
}

.pythonGames__container ol {
    margin-left: 32px;
}

.pythonGames__container ol li {
    font-size: 22px;
    margin-bottom: 12px;
}

.pythonGames__container .code-box {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-x: auto;
    font-family: "Courier New", Courier, monospace;
    margin-top: 22px;
    margin-bottom: 22px;
}


.pythonGames__container .pyExample {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-x: auto;
    font-family: "Courier New", Courier, monospace;
    margin-top: 22px;
    margin-bottom: 22px;
    font-size: 20px;
}