.availability-page {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.availability__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
}

.availability-page .availability-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 44px;
}

.availability-page .availability-container .day-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.availability-page .availability-container .day-wrapper .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 44px;
}

.availability-page .availability-container .day-wrapper .top-container .day-selector {
    width: 20%;
    height: 34px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 7px;
}

.availability-page .availability-container .day-wrapper .timeslot-container {    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    border-radius: 8px;
    width: 18%;
    height: 120px;
    margin-bottom: 24px;
    margin-right: 18px;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.booked {
    background-color: gold;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.booked .time {
    color: black;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.available {
    background-color: rgb(74, 255, 74);
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.available .time {
    color: black;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.unavailable {
    background-color: red;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.unavailable .time {
    color: white;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot .time {
    font-size: 18px;
    font-weight: 700;
}




.availability-page .availability-container .day-wrapper .timeslot-container .slot.unavailable .notonschedule {
    background-color: white;
    margin-top: 12px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 4px;
    border: 2px solid black;

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.unavailable .notonschedule:hover {
    background-color: rgb(204, 204, 204);
}


.availability-page .availability-container .day-wrapper .timeslot-container .slot.available .onschedule {
    background-color: white;
    margin-top: 12px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 4px;
    border: 2px solid black;

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot.available .onschedule:hover {
    background-color: rgb(204, 204, 204);
}

.availability-page .availability-container .day-wrapper .timeslot-container .slot .booked {
    margin-top: 12px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .availability-page .availability-container .day-wrapper .timeslot-container .slot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        border-radius: 8px;
        width: 22%;
        height: 120px;
        margin-bottom: 24px;
        margin-right: 18px;
    }
}


@media screen and (max-width: 920px) {
    .availability-page .availability-container .day-wrapper .timeslot-container .slot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        border-radius: 8px;
        width: 30%;
        height: 120px;
        margin-bottom: 24px;
        margin-right: 12px;
    }
}

@media screen and (max-width: 700px) {
    .availability-page .availability-container .day-wrapper .top-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .availability-page .availability-container .day-wrapper .top-container .day-selector {
        width: 100%;
        margin-bottom: 24px;
    }

    .availability-page .availability-container .day-wrapper .timeslot-container .slot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        border-radius: 8px;
        width: 45%;
        height: 120px;
        margin-bottom: 24px;
        margin-right: 12px;
    }
}

@media screen and (max-width: 500px) {
    .availability-page .availability-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 20px;
    }

    .availability-page .availability-container .day-wrapper .timeslot-container .slot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        border-radius: 8px;
        width: 100%;
        height: 120px;
        margin-bottom: 24px;
        margin-right: 12px;
    }
}