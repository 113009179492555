.class__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 80px;
    border-radius: 0px;
    border: 2px solid rgb(0,0,80);
    padding-right: 8px;
    margin-bottom: 44px;

    -webkit-box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15); 
    box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15);
}

.class__card .class__details {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    height: 100%;
}

.class__card .class__details h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0,0,80);
    color: white;
    height: 100%;
    width: 88px;
    font-size: 18px;
}

.class__card .class__details p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: rgb(0,0,80);
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    padding-left: 8px;
    padding-bottom: 8px;
}

.class__card .card__button__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
}

.class__card .card__button__container .details {
    height: 42px;
    font-size: 16px;
    width: 30%;
    border: none;
    color: #fff;
    background-color: #940DFF;
    border-radius: 4px;
    cursor: pointer;
}

.class__card .card__button__container .attendance {
    height: 42px;
    font-size: 16px;
    width: 30%;
    border: none;
    color: #fff;
    background-color: rgb(37, 69, 252);
    border-radius: 4px;
    cursor: pointer;
}

.class__card .card__button__container .meeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 42px;
    font-size: 16px;
    width: 30%;
    border: none;
    color: #fff;
    background-color: rgb(255, 187, 0);
    border-radius: 4px;
}

.class__card .card__button__container .meeting a {
    border: none;
    color: #fff;
    background-color: transparent;
    width: 100%;
}

.class__card .card__button__container .late-student {
    border: none;
    color: #fff;
    background-color: purple;
    height: 30px;
    font-size: 16px;
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
}


@media screen and (max-width: 1250px) {
    .class__card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        border-radius: 0px;
        border: 2px solid rgb(0,0,80);
        padding-right: 8px;
        margin-bottom: 44px;
    }
}

@media screen and (max-width: 1000px) {
    .class__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 200px;
        border-radius: 0px;
        border: 2px solid rgb(0,0,80);
        padding-right: 8px;
        margin-bottom: 44px;
    }

    .class__card .class__details {
        width: 100%;
    }

    .class__card .class__details h2 {
        width: 120px;
    }

    .class__card .card__button__container {
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    .class__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 300px;
        border-radius: 0px;
        border: 2px solid rgb(0,0,80);
        padding-right: 0px;
        margin-bottom: 44px;
    }

    .class__card .class__details {
        background-color: rgb(0,0,80);
        color: white;
        width: 100%;
        height: 120px;
    }

    .class__card .class__details h2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        color: white;
        height: 100%;
        padding-left: 12px;
        padding-bottom: 12px;
        font-size: 28px;
    }

    .class__card .class__details p {
        color: white;
    }

    .class__card .card__button__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 18px;
    }

    .class__card .card__button__container .details {
        height: 42px;
        font-size: 16px;
        width: 100%;
        border: none;
        color: #fff;
        background-color: #940DFF;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 18px;
    }
    
    .class__card .card__button__container .attendance {
        height: 42px;
        font-size: 16px;
        width: 100%;
        border: none;
        color: #fff;
        background-color: rgb(37, 69, 252);
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 18px;
    }
    
    .class__card .card__button__container .meeting {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 42px;
        font-size: 16px;
        width: 100%;
        border: none;
        color: #fff;
        background-color: rgb(255, 187, 0);
        border-radius: 4px;
        margin-bottom: 18px;
    }
}

@media screen and (max-width: 400px) {
    .class__card .class__details h2 {
        font-size: 18px;
    }

    .class__card .card__button__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 8px;
    }
}