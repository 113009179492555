.teacher-resources {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.teacher-resources .project-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding-left: 44px;
    padding-right: 44px;
    padding-top: 72px;
    padding-bottom: 72px;
}

.teacher-resources .project-menu .curriculum {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;

    border: 1px solid blue;
    border-radius: 4px;
    margin-bottom: 44px;

    -webkit-box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15); 
    box-shadow: 0px 0px 22px 3px rgba(6,0,255,0.15);
}

.teacher-resources .project-menu .curriculum .option.label {
    padding: 12px;
    background-color: blue;
    width: 100%;
    color: white;
}

.teacher-resources .project-menu .curriculum .option.project {
    width: 100%;
    padding-left: 28px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid blue;
    color: blue;
    font-size: 14px;
    text-decoration: underline;

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.teacher-resources .project-menu .curriculum .option.project:hover {
    background-color: rgba(0, 0, 255, 0.13);
}


@media screen and (max-width: 700px) {
    .teacher-resources .project-menu {
        padding-top: 20px;
    }

    .teacher-resources .project-menu .curriculum {
        width: 100%;
    }
}