.progress-card {
  width: 100%;
  margin: auto;
  background-color: white;
  color: #1c1917;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.progress-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
}

.progress-card .card-title {
  font-size: 1.125rem;
  color: #1c1917;
}

.progress-card .icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #1c1917;
}

.progress-card .card-content {
  padding: 1.25rem;
}

.progress-card .file-info {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #525252;
}

.progress-bar-background {
  width: 100%;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  height: 0.625rem;
  overflow: hidden;
}

.progress-bar {
  background-color: #2563eb;
  height: 100%;
  border-radius: 0.25rem;
  width: 0%; /* Start width at 0 */
  transition: width 1s ease-out; /* Smooth transition */
}

.progress-card .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
}

.progress-card .progress-text {
  font-size: 0.875rem;
  color: #525252;
}

.progress-card .details-button {
  font-size: 0.75rem;
  color: #2563eb;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.progress-card .details-button:hover {
  text-decoration: none;
}


/* Smooth dropdown with transition */
.dropdown-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
}

.dropdown-wrapper.open {
  max-height: 200px; /* Adjust based on content height */
}

.dropdown-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 18px;
    color: #1c1917;
}