.home {
    display: flex;
    flex-direction: row;

    width: 100%;
    background-color: transparent;
}

.home .page-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.home .page-right .projects-container {
    width: 95%;
    padding: 80px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
    .home .page-right .projects-container {
        width: 100%;
        padding: 80px;
    
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .home .page-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .home .page-right .projects-container {
        width: 100%;
        padding: 40px;
    
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 400px) {
    .home .page-right .projects-container {
        padding: 20px;
    }
}