.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 72px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: white;
    border-bottom: 1px solid red;

    -webkit-box-shadow: 0px 3px 10px -5px rgba(0,0,0,0.4); 
    box-shadow: 0px 3px 10px -5px rgba(0,0,0,0.4);
}

.navbar .navbar-left p {
    font-size: 18px;
    color: crimson;
    text-decoration: underline;

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.navbar .navbar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width: 50%;
}

.navbar .navbar-right p {
    font-size: 18px;
    color: crimson;
    text-decoration: underline;

    cursor: pointer;
    transition: 0.2s all ease-in;
    margin-left: 32px;
}