.my-progress-page {
    display: flex;
    flex-direction: row;

    width: 100%;
    background-color: transparent;
}

.my-progress-page .page-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.my-progress-page .page-right .myprogress__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    width: 100%;
    height: 30vh;
}

.my-progress-page .page-right .my-progress-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 80px;
    width: 100%;
}


.my-progress-page .page-right .my-progress-container .progress-container-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48%;
}

.my-progress-page .page-right .my-progress-container .progress-container-left .progressbar-container {
    margin-bottom: 44px;
    width: 100%;
}

.my-progress-page .page-right .my-progress-container .progress-container-left .projects {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.my-progress-page .page-right .my-progress-container .progress-container-right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 45%;
}

.my-progress-page .page-right .my-progress-container .progress-container-right .path {
    width: 48%;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(251, 110, 255, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.8px);
    -webkit-backdrop-filter: blur(5.8px);
    border: 2px solid rgb(255, 0, 149);
    margin-bottom: 18px;
    cursor: pointer;
    transition: 0.1s all ease-in;
}

.my-progress-page .page-right .my-progress-container .progress-container-right .path:hover {
    background: rgba(250, 110, 255, 0.507);
}

.my-progress-page .page-right .my-progress-container .progress-container-right .path p {
    margin-top: 18px;
    font-size: 22px;
    font-weight: 300;
    text-align: center;
}



.glitch-wrapper {
   width: 100%;
   margin-top: 18px;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   background-color: transparent;
}

.glitch {
   position: relative;
   font-size: 20px;
   font-weight: 700;
   line-height: 1.2;
   color: #010035;
   letter-spacing: 4px;
   z-index: 1;
}

.glitch:before {
   content: attr(data-glitch);
   position: absolute;
   top: 0;
   left: -2px;
   width: 100%;
   color: #010035;
   background-color: rgba(33, 33, 33, 0);
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-before 3s infinite linear alternate-reverse;
}

.glitch:after {
   content: attr(data-glitch);
   position: absolute;
   top: 0;
   left: 2px;
   width: 100%;
   color: #010035;
   background-color: rgba(33, 33, 33, 0);
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-after 2s infinite linear alternate-reverse;
}

@keyframes noise-before {
   0% {
      clip: rect(61px, 9999px, 52px, 0);
   }

   5% {
      clip: rect(33px, 9999px, 144px, 0);
   }

   10% {
      clip: rect(121px, 9999px, 115px, 0);
   }

   15% {
      clip: rect(144px, 9999px, 162px, 0);
   }

   20% {
      clip: rect(62px, 9999px, 180px, 0);
   }

   25% {
      clip: rect(34px, 9999px, 42px, 0);
   }

   30% {
      clip: rect(147px, 9999px, 179px, 0);
   }

   35% {
      clip: rect(99px, 9999px, 63px, 0);
   }

   40% {
      clip: rect(188px, 9999px, 122px, 0);
   }

   45% {
      clip: rect(154px, 9999px, 14px, 0);
   }

   50% {
      clip: rect(63px, 9999px, 37px, 0);
   }

   55% {
      clip: rect(161px, 9999px, 147px, 0);
   }

   60% {
      clip: rect(109px, 9999px, 175px, 0);
   }

   65% {
      clip: rect(157px, 9999px, 88px, 0);
   }

   70% {
      clip: rect(173px, 9999px, 131px, 0);
   }

   75% {
      clip: rect(62px, 9999px, 70px, 0);
   }

   80% {
      clip: rect(24px, 9999px, 153px, 0);
   }

   85% {
      clip: rect(138px, 9999px, 40px, 0);
   }

   90% {
      clip: rect(79px, 9999px, 136px, 0);
   }

   95% {
      clip: rect(25px, 9999px, 34px, 0);
   }

   100% {
      clip: rect(173px, 9999px, 166px, 0);
   }
}

@keyframes noise-after {
   0% {
      clip: rect(26px, 9999px, 33px, 0);
   }

   5% {
      clip: rect(140px, 9999px, 198px, 0);
   }

   10% {
      clip: rect(184px, 9999px, 89px, 0);
   }

   15% {
      clip: rect(121px, 9999px, 6px, 0);
   }

   20% {
      clip: rect(181px, 9999px, 99px, 0);
   }

   25% {
      clip: rect(154px, 9999px, 133px, 0);
   }

   30% {
      clip: rect(134px, 9999px, 169px, 0);
   }

   35% {
      clip: rect(26px, 9999px, 187px, 0);
   }

   40% {
      clip: rect(147px, 9999px, 137px, 0);
   }

   45% {
      clip: rect(31px, 9999px, 52px, 0);
   }

   50% {
      clip: rect(191px, 9999px, 109px, 0);
   }

   55% {
      clip: rect(74px, 9999px, 54px, 0);
   }

   60% {
      clip: rect(145px, 9999px, 75px, 0);
   }

   65% {
      clip: rect(153px, 9999px, 198px, 0);
   }

   70% {
      clip: rect(99px, 9999px, 136px, 0);
   }

   75% {
      clip: rect(118px, 9999px, 192px, 0);
   }

   80% {
      clip: rect(1px, 9999px, 83px, 0);
   }

   85% {
      clip: rect(145px, 9999px, 98px, 0);
   }

   90% {
      clip: rect(121px, 9999px, 154px, 0);
   }

   95% {
      clip: rect(156px, 9999px, 44px, 0);
   }

   100% {
      clip: rect(67px, 9999px, 122px, 0);
   }
}



.my-progress-page .page-right .my-progress-container .progress-container-right .path .path-icon {
    color: rgb(255, 0, 149);
}



@media screen and (max-width: 1200px) {
   .my-progress-page .page-right .my-progress-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-left: 150px;
      padding-right: 50px;
      padding-top: 80px;
      width: 100%;
   }

   .my-progress-page .page-right .my-progress-container .progress-container-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 50%;
  }
}

@media screen and (max-width: 1000px) {

   .my-progress-page .page-right .my-progress-container .progress-container-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 45%;
  }
  
  .my-progress-page .page-right .my-progress-container .progress-container-right .path {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(251, 110, 255, 0.19);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5.8px);
      -webkit-backdrop-filter: blur(5.8px);
      border: 2px solid rgb(255, 0, 149);
      margin-bottom: 18px;
      cursor: pointer;
      transition: 0.1s all ease-in;
   }
}

@media screen and (max-width: 800px) {
   .my-progress-page .page-right .my-progress-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding-left: 150px;
      padding-right: 50px;
      padding-top: 80px;
      width: 100%;
   }

   .my-progress-page .page-right .my-progress-container .progress-container-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 24px;
   }

   
   .my-progress-page .page-right .my-progress-container .progress-container-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
   }

   .my-progress-page .page-right .my-progress-container .progress-container-right .path {
      width: 48%;
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(251, 110, 255, 0.19);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5.8px);
      -webkit-backdrop-filter: blur(5.8px);
      border: 2px solid rgb(255, 0, 149);
      margin-bottom: 18px;
      cursor: pointer;
      transition: 0.1s all ease-in;
   }
}

@media screen and (max-width: 550px) {
   .my-progress-page .page-right .my-progress-container .progress-container-right .path {
      width: 100%;
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(251, 110, 255, 0.19);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5.8px);
      -webkit-backdrop-filter: blur(5.8px);
      border: 2px solid rgb(255, 0, 149);
      margin-bottom: 18px;
      cursor: pointer;
      transition: 0.1s all ease-in;
   }
}

@media screen and (max-width: 500px) {
   .my-progress-page .page-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
   }

   .my-progress-page .page-right .my-progress-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      width: 100%;
   }

   .my-progress-page .page-right .my-progress-container .progress-container-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
   }
}